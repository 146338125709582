<template>
   <div
          class="menu-item " :class="{'menu-item_opened': menuItemOpened, 'menu-item_active': false}"
        >
          <router-link v-if="item.to || item.slug " 
            :to="item.to ? 
              item.to : 
              { name: 'PromoCarousel', params: {slug: item.slug, country: code } }" 
            class="menu-item__header">
              <div class="menu-item__name">
                  <div class="menu-item__arrow" v-if="item.code || item.children">
                    <v-icon>
                      mdi-chevron-down
                    </v-icon>
                  </div>
                  <div>{{item.name}}</div>
              </div>
          </router-link>

          <div v-else class="menu-item__header">
              <div class="menu-item__name"  @click.prevent="menuItemOpened = !menuItemOpened">
                  <div class="menu-item__arrow" v-if="item.code || item.children">
                    <v-icon>
                      mdi-chevron-down
                    </v-icon>
                  </div>
                  <div>{{item.name}}</div>
              </div>
          </div>

          <div class="menu-item__list" v-if="depth === 0">
            <MenuItem  v-for="link in menuCategories" :code="code" :key="link.id" :item="link" :depth="depth+1"/>
          </div>

          <div class="menu-item__list" v-if="item.children && item.children.length">
            <MenuItem  v-for="link in item.children" :code="code" :key="link.id" :item="link" :depth="depth+1"/>
          </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "MenuItem",
  props: {
   item: Object,
   depth: Number,
   code: String
  },
  components: {
   
  },
  data: (vm) => ({
    menuCategories: [
      {
        id: 0,
        name: "Промокарусели",
        children: vm.item.carousels
      },
      {
        id: 1,
        name: "Промостраница",
        to: {name: "PromoList", params: {country: vm.item.code}}
      }
    ],
    menuItemOpened: false
  }),
  computed: {
    // ...mapGetters(['getCarouselList'])
  },
  methods: {
    // ...mapActions(['fetchCarouselList'])
  },
  mounted() {
    // if(this.depth === 1 && this.item.children) {
    //   this.fetchCarouselList()
    // }
  }
};
</script>

<style scoped lang="scss">
.main-menu { 
    > .menu-item {
      padding-bottom: 15px;
      margin-bottom: 30px;
      margin-left: 0;
      border-bottom: 1px solid #DADADA;
        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
            margin: 0 auto;
        }
        .menu-item__header {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 19px;
            line-height: 24px;
            color: #56565A;
            &:hover {
                cursor: pointer;
                color: #0A467E;
                transition: .3s all;
            }

            & > a {
                font-family: inherit;
                font-style: inherit;
                font-weight: inherit;
                font-size: inherit;
                line-height: inherit;
                color: inherit;
                text-decoration: none;
            }
        }

        .menu-item__document {
          font-weight: normal !important;
        }

        .menu-item {
          .menu-item__header {
            font-size: 17px;
            line-height: 21px;
            font-weight: 600;
            &.router-link-exact-active {
              font-weight: 600;
              color: #0A467E;
            }
          }

           .menu-item {
            .menu-item__header  {
              font-weight: normal;
            }
          }
        }
    }
}

.menu-item {
    margin-left: 45px;
    & .v-icon {
      color: currentColor;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        /* cursor: pointer; */
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #56565A;
        cursor: pointer;
        text-decoration: none;

        &:hover .project__menu {
          opacity: 0.7;
        }
    }

    &__name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      & a {
        display: block;
        width: 100%;
        color: inherit;
        text-decoration: none;
      }
    }

    &__arrow {
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;
        cursor: pointer;
    }

    &__list {
        max-height: 0;
        overflow: hidden;
        transition: .3s;

        & .menu-item {
            margin-left: 45px;
        }
    }
}

.menu-item_current {
    > .menu-item__header {
        color: #0A467E;
        font-weight: 600;
    }
}

.menu-item_opened {
    > .menu-item {
        &__list {
            max-height: 5000px;
            overflow: visible;
            border-left: 1px solid #DADADA;
        }
        &__header {
            font-weight: 600;
            > .menu-item__name .menu-item__arrow {
                transform: rotate(180deg);
            }
        }
    }
}

.main-menu .menu-item_active {
    > .menu-item__header {
        font-weight: 600;
        color: #0A467E;
    }
}

.project__tools {
  margin-left: auto;
  position: relative;

  & > .project__menu {
    opacity: 0;
    cursor: pointer;
    transition: 0.3s;
    color: #000;
  }

  & .project__menu:hover {
    opacity: 1;
    color: #000;
  }

}

</style>